import React from 'react'
import './Contact.css'
import Photo4 from './SVG/photo4.jpg'
import AlertCard from './alertCard'

class Contact extends React.Component {
    constructor(props) {
        
      super(props);
      this.state = {
        formData: {
          name: '', surname: '',
          email: '',
          message: ''
        },
        errors: {
          name: 0, surname: 0,
          email: 0,
          message: 0
        },
        alerts: {
          isShown:0,
          title:'title',
          message:'message'
        }
      }
      this.checkErrors.bind(this);
      this.submit.bind(this);
      
    }

    
    


    render() {
      return(
        <div className="contact-wrapper">
          <AlertCard isShown= {this.state.alerts.isShown} title={this.state.alerts.title} message={this.state.alerts.message} clickEvent={this.handleAlertClick.bind(this)}/>
          <h1>... and what is civilization if it isn't people talking to each other over a goddamned beer?</h1>
          <h2>- James S. A. Corey</h2>
          
          <img src={Photo4} className='photo-contact' alt=""/>

          <h3 style={{paddingLeft: '40px'}}>
            Here, contact me freely and let's have a chat.
          </h3>
          <div id="contact-form" className='contact-form'>
            <div className='group-box'>
                <label htmlFor="name">Name:</label>
                <input  type="text" className="form-control" 
                        value={this.state.formData.name} 
                        onChange={this.onNameChange.bind(this)} />
                {this.state.errors.name ?
                      <span className='error'>Required field</span> : null}
            </div>  
            <div className='group-box'>
                <label htmlFor="surname">Surname:</label>
                <input  type="text" 
                        className="form-control" 
                        value={this.state.formData.surname} 
                        onChange={this.onSurnameChange.bind(this)} />
                {this.state.errors.surname ?
                      <span className='error'>Required field</span> : null}
            </div>
            <div className='group-box'>
                <label htmlFor="exampleInputEmail1">Email address:</label>
                <input  type="email" 
                        className="form-control" 
                        aria-describedby="emailHelp" 
                        value={this.state.formData.email} 
                        onChange={this.onEmailChange.bind(this)} />
                {this.state.errors.email ?
                      <span className='error'>Required field</span> : null}
            </div>
            <div style={{position: 'relative',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column'}}>
                <label htmlFor="message">Message:</label>
                {this.state.errors.message ?
                      <span className='error'
                      style={{position: 'absolute',
                              bottom: '90%', left: '5%'}}
                        >Required field</span> : null}
                <textarea className="form-control" 
                        rows="5" value={this.state.formData.message} 
                        onChange={this.onMessageChange.bind(this)} />
                
                <button type="submit" className="submit-btn" onClick={this.validate.bind(this)}>Send</button>
            </div>
            
          </div>
        </div>
      );
    }
    validate() {
      this.checkErrors()
      if (this.state.errors.name || this.state.errors.surname || this.state.errors.email || this.state.errors.message) {
        return;
      }
      else {
        this.submit()
      }
    }
    handleAlertClick() {
      let newAlert = this.state.alerts;
      newAlert.isShown=0;
      this.setState({alerts: newAlert})
    }
    onNameChange(event) {
      let newformData = this.state.formData;
      newformData.name = event.target.value;
      this.setState({formData: newformData});
      let newErrors = this.state.errors;
      (this.state.formData.name === "") ? newErrors.name=1 : newErrors.name=0;
      this.setState({errors: newErrors})
    }
    
    onSurnameChange(event) {
      let newformData = this.state.formData;
      newformData.surname = event.target.value;
      this.setState({formData: newformData});
      let newErrors = this.state.errors;
      (this.state.formData.surname === "") ? newErrors.surname=1 : newErrors.surname=0;
      this.setState({errors: newErrors})
    }
    onEmailChange(event) {
      let newformData = this.state.formData;
      newformData.email = event.target.value;
      this.setState({formData: newformData});
      let newErrors = this.state.errors;
      (this.state.formData.email === "") ? newErrors.email=1 : newErrors.email=0;
      this.setState({errors: newErrors})
    }
    onMessageChange(event) {
      let newformData = this.state.formData;
      newformData.message = event.target.value;
      this.setState({formData: newformData});
      let newErrors = this.state.errors;
      (this.state.formData.message === "") ? newErrors.message=1 : newErrors.message=0;
      this.setState({errors: newErrors})
    }
    
    checkErrors(){
      let newErrors = this.state.errors;
      (this.state.formData.name === "") ? newErrors.name=1 : newErrors.name=0;
      (this.state.formData.surname === "") ? newErrors.surname=1 : newErrors.surname=0;
      (this.state.formData.email === "") ? newErrors.email=1 : newErrors.email=0;
      (this.state.formData.message === "") ? newErrors.message=1 : newErrors.message=0;
      this.setState({errors: newErrors})
    }
    submit() {
      this.handleAlertBox.bind(this)
      fetch('https://us-central1-serverless-contact-form-303421.cloudfunctions.net/python-contact-form', {
          crossDomain:true,
          method: "POST",
          body: JSON.stringify(this.state.formData),
          headers: {'Content-Type': 'application/json'}})
          .then((response)=> { 
        if (response.status === 200) {
          this.handleAlertBox("Success!", "I have received your message. I will contact you as soon as possible.")
          this.resetForm()
        } else if(response.status === 500) {
          this.handleAlertBox("Ooops!", "Something went wrong, please try again.")
        } else if(response.status === 400) {
          this.handleAlertBox("Ooops!", "Please enter a valid e-mail address.")
        }
      })
    }
  
  handleAlertBox(tle,msg){
    this.setState({ alerts:{ title: tle, message: msg, isShown : 1}})
  }
  resetForm(){
      this.setState(
        {
          formData: {
            name: '', surname: '',
            email: '',
            message: ''
          },
          errors: {
            name: 0, surname: 0,
            email: 0,
            message: 0
          }
        })
  }
  
  }


export default Contact