import './Button.css'
import React from 'react'


function Button(props) {
    return(
        <div className={`buttonWithText ${props.isClicked ? "clicked" : "notClicked"}`} onClick={() => props.clickState(props.text)}>
            <div className='Button'>
                <div className='circle'></div>
                <div className={`first ${props.isClicked ? "clicked" : "notClicked"}`}></div>
                <div className={`second ${props.isClicked ? "clicked" : "notClicked"}`}></div>
                <div className={`third ${props.isClicked ? "clicked" : "notClicked"}`}></div>
                <div className={`fourth ${props.isClicked ? "clicked" : "notClicked"}`}></div>
                <div className={`fifth ${props.isClicked ? "clicked" : "notClicked"}`}></div>
                <div className={`sixth ${props.isClicked ? "clicked" : "notClicked"}`}></div>
                <div className={`seventh ${props.isClicked ? "clicked" : "notClicked"}`}></div>
            </div>
            <div className='buttonText'>{props.text}</div>
        </div>
    )
}

export default Button

