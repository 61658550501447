import './PortfolioCard.css'
import React from 'react'
import CardImage from './SVG/mobile.png'

class PortfolioCard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isMouseOver: 0
      }
    }
    render() {
        return(
            <div className={`card-container ${this.state.isMouseOver ? "active" : "notActive"}`} onMouseLeave={()=>{this.onMouseOver(0)}}>
                <div className={`card-image ${this.state.isMouseOver ? "active" : "notActive"}`}><img src={CardImage} alt=""/></div>
                <div className='portfolio-content'>
                    <div className='portfolio-visual'  onMouseEnter={()=>{this.onMouseOver(1)}} onClick={()=>{this.handleClick(1)}}> 
                            <img src={this.props.visual} 
                                alt="portfolio visual" /></div>
                    <div>
                    
                    <div className={`card-text ${this.state.isMouseOver ? "active" : "notActive"}`}>
                        <h2>{this.props.title}</h2>
                        <p>{this.props.text}</p>
                    </div>
                    </div>
                </div>
                <div className={`x-btn ${this.state.isMouseOver ? "active" : "notActive"}`} onClick={()=>{this.handleClick(0)}}>
                  <div style={{
                    position: 'relative',
                    top:'45%',
                    left:'15%',
                    background:'rgba(255, 123, 0, 0.5)',
                    width: '15px',
                    height:'3px',
                    transform: 'rotate(45deg)'
                    
                  }}></div>
                    <div style={{
                    position: 'relative',
                    top:'30%',
                    left:'15%',
                    background:'rgba(255, 123, 0, 0.5)',
                    width: '15px',
                    height:'3px',
                    transform: 'rotate(-45deg)'
                    
                  }}></div>
                </div>
 
            </div>
        )
    }
  
    onMouseOver(e) {
      this.setState({isMouseOver: e})
    }
    handleClick(e) {
      this.setState({isMouseOver: e})
    }
  }


export default PortfolioCard