import './App.css';
import Navbar from './Navbar';import MobileNavbar from './MobileNavbar';
import BackgroundVideo from './BackgroundVideo';
import MobileBackground from './MobileBackground';
import Screen from './Screen';
import MobileScreen from './MobileScreen';

import React, {useLayoutEffect, useState} from 'react';

function App() {
  const initialState = {aboutMe: 0, 
    home: 0,
    sudoku: 0,
    portfolio: 0,
    contact: 0,
    title: '',
    active: 0,
  }
  
  const [state, setState] = useState(initialState)
  
  
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  
  
  

  function animationFinished(){
    setState({aboutMe: 0, 
      home: 1,
      contact: 0,
      title: 'Home',
      active: 1
    });
  }

  function clickState (id) {
    if (id===state.title){
      id=''
    } 
    switch(id){
        case 'About Me':
            setState({aboutMe: 1, 
                      home: 0,
                      contact: 0,
                      title: id,
                      active: 1,
            });return '';
        case 'Home':
            setState({aboutMe: 0, 
                      home: 1,
                      contact: 0,
                      title: id,
                      active: 1
            });return '';

    
        case 'Contact':
            setState({aboutMe: 0, 
                      home: 0,
                      contact: 1,
                      title: id,
                      active: 1
            });return '';

        default:
            setState({aboutMe: 0, 
                home: 0,
                contact: 0,
                title: id,
                active: 0
            });return '';
    }
  }


  return (
    <div className="App">
        {(size[0]>900) ?<BackgroundVideo isActive={state.active} animationFinished={animationFinished} />: <MobileBackground isActive={state.active} animationFinished={animationFinished}/>}
        {(size[0]>900) ? <Navbar clickState={clickState} {...state}/> : <MobileNavbar clickState={clickState} {...state}/>}
        
        {(size[0]>900) ? <Screen title={state.title} isActive={state.active}/> : <MobileScreen title={state.title} isActive={state.active}/>}
    </div>
    
      
  );
}

export default App;
