import './MobileNavbar.css'
import React from 'react'
import {CSSTransition} from 'react-transition-group'

function MobileNavbar(props) {
    return(
        <div className='MobileNavbar'>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='nav-animation'
                >
                    <button className={`nav-btn home ${props.home ? "mobileclicked" : "mobilenotClicked"}`} 
                    onClick={() => props.clickState("Home")}>
                        Home
                    </button>
            </CSSTransition>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='nav-animation'
                >
                <button className={`nav-btn about-me ${props.aboutMe ? "mobileclicked" : "mobilenotClicked"}`} 
                        onClick={() => props.clickState('About Me')}>
                    About Me
                </button>
            </CSSTransition>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='nav-animation'
                >
                <button className={`nav-btn contact ${props.contact ? "mobileclicked" : "mobilenotClicked"}`} 
                        onClick={() => props.clickState('Contact')}>
                    Contact
                </button> 
            </CSSTransition>
        </div>
    )    
}

export default MobileNavbar