import React from 'react'
import Photo2 from './SVG/photo2.jpg'
import Photo3 from './SVG/photo3.jpg'
import Photo4 from './SVG/photo4.jpg'
import Photo5 from './SVG/photo5.jpg'
import Photo6 from './SVG/photo6.jpg'
import './AboutMe.css'

function AboutMe(){
    return(
        <div className='text-wrapper'>
            <h1>You don't have to burn books to destroy a culture. Just get people to stop reading.</h1>
            <h2>- Ray Bradbury</h2>
            <div className='block'>
                <img src={Photo5} className='photo-two' alt=""/>
                <h3>Hi! I am Yunus.</h3>
                <p>
                    I am a Sci-fi (Hence the website) and a fantasy geek. 
                    I have 5+ years of coding experience in projects varying from mechanical to electrical to purely codebased.
                    I love learning. It is a part of my personality. 
                </p>
            </div>
            
            <h1>Education</h1>
            <div className='block'>
                <img src={Photo2} className='photo-two' alt=""/>
                <p>
                    I studied Mechanical Engineering in the finest university in Turkey. 
                    But my desire to learn eventually led me to do a double major with Electrical and Electronics Engineering.
                    Meantime, I also did several internships and worked part time in a renowned company as a paid intern.
                </p>
                <p>
                    Ever since I got a taste of coding during my bachelor's education, I knew I couldn't stop.
                    I have finished many coding related projects during my bachelor's education including image processing, serial communications, graphical user interfaces, pathfinding, and numerical solvers.
                    I have also done many 'garage projects' as a hobby, one of which was modifying my car's stereo with an arduino. 
                </p>
                <p>
                    During one of those projects, I stumbled upon image processing using neural networks. 
                    I immediately knew what I wanted to learn next, Neural Networks. 
                    Being an Isaac Asimov fan myself, it wasn't even an option. 
                </p>
            </div>
            <div className='block'>
                <img src={Photo3} className='photo-three' alt=""/>
                <p>
                    Eventually, my enthusiasm led me to Tokyo, Japan. I studied my Master's degree on Neural Networks in Tokyo Institute of Technology, one of the finest universities in Japan.
                    After quite a lot of learning and hard-work I have successfully finished my Master's Thesis 'Knee joint pose estimation using deep neural network for transfemoral prosthesis'.
                </p>
                <p>Stacking one more diploma to my collection.</p>
            </div>
            
            <h1>Work</h1>
            <div className='block'>
                <img src={Photo6} className='photo-three' alt=""/>
                <p>
                    I have 5+ of coding experience in different fields. 
                    When I started my master's on deep neural network at Tokyo Institute of Technology, I felt like I have finally found a field that will satisfy my curiosity, Machine Learning and Data Science. 
                    Because of the large diversity of applications, it requires constant learning and problem solving. Not only that, I have this weird connection with computers... I feel like I can talk to them... As if... I know their languages, C/C++, Python, R, HTML, CSS, JS, React etc.
                </p>
                <p>
                    Having studied multiple disciplines, I have acquired an important skill, ability to design an entire system.
                    I find it quite enjoying to design User Interfaces as well. Both these combined led me to be a full stack developer.
                </p>
                <p>
                    Since there are very few jobs that let me work in a wide variety of disciplines, I have decided to work as a freelancer.

                </p>
            </div>
            <h1>Hobbies and Interests</h1>
            <div className='block'>
                <img src={Photo4} className='photo-four' alt=""/>
                <p>
                    Well, I am a reader, especially sci-fi and fantasy. 
                    I also love world-building. I dream of writing my own novel one day. 
                </p>
                <p>
                    I also love music, travelling and cooking. 
                </p>
                
            </div>

        </div>
    )
}

export default AboutMe