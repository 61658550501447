import './Navbar.css'
import React from 'react'
import Button from './Button';
import {CSSTransition} from 'react-transition-group'

function Navbar(props) {
    return(
        <div className='Navbar'>
           <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='home-animation'
                >
                    <Button text='Home'
                        isClicked={props.home} 
                        clickState={props.clickState}/>
            </CSSTransition>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='aboutMe-animation'
                >
                    <Button text='About Me' 
                        isClicked={props.aboutMe} 
                        clickState={props.clickState}/>
            </CSSTransition>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='contact-animation'
                >
                    <Button text='Contact'
                        isClicked={props.contact} 
                        clickState={props.clickState}/>
            </CSSTransition>
        </div>
    )    
}

export default Navbar