import React from 'react'
import Back from './SVG/screenpng.png'

import './Screen.css'
import AboutMe from './AboutMe'
import Home from './Home'
import Contact from './Contact'


class Screen extends React.Component{
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
          
        }
      }
  
      render() {
        return(
            <>
            <div className={`line ${this.props.isActive ? "active" : "notActive"}`}></div>
            <div className={`linetwo ${this.props.isActive ? "active" : "notActive"}`}></div>
            <div className={`top ${this.props.isActive ? "active" : "notActive"}`}>
                
                <div className='title'>{this.props.title}</div>
                <div className='back'><img src={Back} alt=""/></div>
                <div className='content' id='content' ref={this.ref}>
                    {(this.props.title === 'About Me') ? <AboutMe /> : null}
                    {(this.props.title === 'Home') ? <Home /> : null}
                    {(this.props.title === 'Contact') ? <Contact /> : null}
                </div>
            </div>
            </>
        )
      }
      componentDidUpdate() {
        this.ref.current.scrollTop=0;  
      }
      onScroll() {
        this.props.handleScroll( this.ref.current.scrollTop );
        }
}

export default Screen


