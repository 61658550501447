import React from 'react'
import Photo1 from './SVG/photo1.jpg'
import Photo3 from './SVG/photo3.jpg'

import './Home.css'
import PortfolioCard from './PortfolioCard'

//portfolio
import portfolioMasters from './portfolio/portfolioMasters.png'
import onGoing from './portfolio/ongoing.jpg'

function AboutMe(){

    const portfolioOne={
        visual: portfolioMasters,
        text: "This is my master's thesis at Tokyo Institute of Technology. Predicting the knee joint angle for robotic above-knee prosthesis is a problem. I have trained a LSTM network that predicts the required knee joint angle depending on realtime inputs. The project included data gathering, data cleaning, data analyzing, and machine learning.",
        title: "Master's Thesis: Knee joint pose estimation using deep neural network for transfemoral prosthesis"
    }

    const portfolioTwo={
        visual: onGoing,
        text: "Application that lets people match on certain topic depending on selection (whether same opinion on opposite) to argue.",
        title: "On going application project"
    }

    const portfolioThree={
        visual: onGoing,
        text: "A website that gives suggested products depending on user's data with an engine driven by machine learning",
        title: "On going website and machine learning project"
    }

    return(
        <div className='text-wrapper'>
            <h1>I do not fear the computers. I fear the lack of them.</h1>
            <h2>- Isaac Asimov</h2>
            <div className='block'>
                <img src={Photo1} className='photo-one' alt=""/>
                <h3>Hi! I am Yunus. I am a polymath.</h3>
                <h3>Inquisitive, Inventive, Studious.</h3>
                <p>I love learning. It is a part of my personality.</p>
            </div>
            <h1>&emsp;</h1>
            <div className='block'>
                <img src={Photo3} className='photo-two' alt=""/>
                <p>Mechanical Engineering(B.Sc) at Middle East Technical University</p>
                <p>Electrical and Electronics Engineering(Double Major B.Sc.) at Middle East Technical University</p>
                <p>Master's Thesis on Neural Networks at Tokyo Institute of Technology.</p>
                <p>Working as a freelancer.</p>
            </div>
            <h1>&emsp;</h1>
            <div className='block'>
                <p>General Skills:</p>
                <p><b>Languages:</b>  Python, C/C++, R, HTML, CSS, JavaScript, MySQL</p>
                <p><b>Tools:</b>  Git/GitHub, Adobe Illustrator/xD/PhotoShop, Vscode</p>
                <p><b>Operating System:</b> Linux, Windows</p>
            </div>
            <div className='skill-table'>
                <div className='table-rows'>
                    <div className='table-block'>
                        <h1>Front-end</h1>
                    </div>
                    <div className='table-block'>
                        <h1>Back-end</h1>
                    </div>
                    <div className='table-block'>   
                        <h1>Machine Learning</h1>
                    </div>
                </div>
                <div className='table-rows'>
                    <div className='Front-end'>
                        <ul>
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>JavaScript</li>
                            <li>React</li>
                            <li>WordPress</li>
                            <li>Shopify</li>
                        </ul>
                        
                    </div>
                    
                    <div className='Back-end'>
                        <ul>
                            <li>django</li>
                            <li>Flask</li>
                            <li>Node.js</li>
                            <li>Firebase</li>
                            <li>WordPress</li>
                            <li>Shopify</li>
                        </ul>
                        
                    </div>
                    <div className='Machine-learning'>   
                        <ul>
                            <li>Scikit-Learn</li>
                            <li>TensorFlow</li>
                            <li>PyTorch</li>
                            <li>Keras</li>
                            <li>Firebase ML Kit</li>
                            <li>Numpy/Scipy</li>
                        </ul>
                    </div>
                </div>
                <h2 style={{alignSelf:'start', marginTop:'40px', marginBottom:'0px'}}>Front-end</h2>
                <p>&emsp;
                    I simply love designing graphical user interfaces.
                    I would even make one in my school projects 
                    for the fun of it even though it wasn't required at all.
                </p>
                <h2 style={{alignSelf:'start', marginTop:'0px', marginBottom:'0px'}}>Back-end</h2>
                <p>&emsp;
                    I really like working on back-end. Something About
                    imagining an entire system, interaction of pieces,
                    harmony of various parts intrigues me.
                    Due to my data science/machine learning background,
                    I prefer using python as a backend language. 
                </p>
                <h2 style={{alignSelf:'start', marginTop:'0px', marginBottom:'0px'}}>Machine Learning</h2>
                <p>
                    &emsp;Due to my double major and wide knowledge in various fields,
                    I have acquired a wider domain knowledge which allows me to think
                    from multiple angles. This, in return, helps with analyzing data and 
                    coming up with domain specific solution.
                </p>
            </div>
            <h1>Portfolio</h1>
            <div className='portfolio-block'>
                <PortfolioCard id='thesis' {...portfolioOne}/>
                <PortfolioCard id='project1' {...portfolioTwo}/>
                <PortfolioCard id='project1' {...portfolioThree}/>
            </div>

        </div>
    )
}

export default AboutMe