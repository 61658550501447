import './alertCard.css'
import React from 'react'
import CardImage from './SVG/alertbox.png'

function AlertCard(props) {
    return(
        <div className={`alert-container ${props.isShown ? "active" : "notActive"}`}>
            <img src={CardImage} alt=""/>
            
            <h1>{props.title}</h1>
            <p>{props.message}</p>
            <button className="alert-btn" onClick={() => props.clickEvent()}>Okay</button>
           
            
        </div>
    )
  }

export default AlertCard