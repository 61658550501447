import React from 'react'
import {CSSTransition} from 'react-transition-group'
import Typing from 'react-typing-animation';
import './BackgroundVideo.css'
import Video from './background/video.mp4'
import Bottom from './SVG/bottom.png'
import Top from './SVG/ust.png'
import Picture from './SVG/cropped.jpg'

const BackgroundVideo = (props) => {
    return (
        <div className='background'>
            <div className='background-top'>
                <CSSTransition
                    in={true}
                    appear={true}
                    timeout={4000}
                    enter={false}
                    classNames='top-image-animation'
                >
                    <img src={Top} className='top-image' alt=""/>
                </CSSTransition>
                
                <CSSTransition
                    in={true}
                    appear={true}
                    enter={false}
                    timeout={4000}
                    classNames='picture-animation'
                >
                    <img src={Picture} className='picture' alt=""/>
                </CSSTransition>
                <CSSTransition
                    in={true}
                    appear={true}
                    enter={false}
                    timeout={4000}
                    classNames='top-light-animation'
                >
                    <div className='top-light'></div>
                </CSSTransition>
                <Typing startDelay={500} speed={1} timeout={0} onFinishedTyping={()=> {props.animationFinished()}}>
                    <div className='name'>
                        Yunus Emre BADEM
                    </div>
                    <div className='job-title'>
                        <p>Full Stack Developer</p>
                        <p>Machine Learning Engineer</p>
                    </div>
                </Typing>
            </div>

            <CSSTransition
                in={true}
                appear={true}
                timeout={4000}
                enter={false}
                classNames='bottom-image-animation'
            >
                <div className='bottom'>
                    <img className='bottom-image' src={Bottom} alt=""/>
                </div>
            </CSSTransition>
            <div className={`lights ${props.isActive ? "active" : "notActive"}`}>
                <div className='light1'></div>
                <div className='light2'></div>
            </div>
            <CSSTransition
                    in={true}
                    appear={true}
                    enter={false}
                    timeout={2000}
                    classNames='video-animation'
                >
                    <video autoPlay loop muted className='background-video'>
                        <source src={Video} type='video/mp4'/>
                    </video>
            </CSSTransition>
            
        </div>
    )
}

export default BackgroundVideo