import React from 'react'
import {CSSTransition} from 'react-transition-group'
import './MobileBackground.css'
import Video from './background/video.mp4'
import MobileBottom from './SVG/mobileBottom.png'
import MobileTop from './SVG/mobileTop.png'

import Picture from './SVG/cropped.jpg'

const MobileBackground = (props) => {
    return (
        <div className='mobilebackground'>
            
            <CSSTransition
                in={true}
                appear={true}
                timeout={4000}
                enter={false}
                classNames='mobiletop-image-animation'
            >   
                <img src={MobileTop} className='mobiletop-image' alt=""/>
            </CSSTransition>
            
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='mobilepicture-animation'
            >
                <img src={Picture} className={`mobilepicture ${props.isActive ? "active" : "notActive"}`} alt=""/>
            </CSSTransition>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={2000}
                classNames='mobiletop-light-animation'
            > 
                <div className='mobiletop-light2'></div>

            </CSSTransition>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={2000}
                classNames='mobiletop-light-animation'
            > 
                <div className='mobiletop-light1'></div>

            </CSSTransition>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='mobilepicture-animation' >   
                <div className={`mobilename ${props.isActive ? "active" : "notActive"}`}>
                    Yunus Emre BADEM
                </div>
            </CSSTransition>
            <CSSTransition
                in={true}
                appear={true}
                enter={false}
                timeout={4000}
                classNames='mobilepicture-animation'>
                <div className={`mobilejob-title ${props.isActive ? "active" : "notActive"}`}>
                    <p>Full Stack Developer</p>
                    <p>Machine Learning Engineer</p>
                </div>
            </CSSTransition>
                
                
            
            

            <CSSTransition
                in={true}
                appear={true}
                timeout={4000}
                enter={false}
                classNames='mobilebottom-image-animation'
            >
                    <img className='mobilebottom-image' src={MobileBottom} alt=""/>
            </CSSTransition>
            <div className={`mobilelights ${props.isActive ? "active" : "notActive"}`}>
                <div className='mobilelight1'></div>
                <div className='mobilelight2'></div>
            </div>
            <CSSTransition
                    in={true}
                    appear={true}
                    enter={false}
                    timeout={2000}
                    classNames='mobilevideo-animation'
                >
                    <video autoPlay loop muted className='mobilebackground-video'>
                        <source src={Video} type='video/mp4'/>
                    </video>
            </CSSTransition>
            
        </div>
    )
}

export default MobileBackground